import { Warning } from "components/icons/warning";
import { DiscountTag } from "components/icons/discount-tag";
import { Callout } from "components/icons/callout";
import { IAlertVariants, IAlertData } from "./alert.types";
import { theme } from "styles";

export const alertData: Record<IAlertVariants, IAlertData> = {
  error: {
    color: theme.colors.red[100],
    iconColor: theme.colors.red[100],
    backgroundColor: theme.colors.magenta[100],
    image: <Warning />
  },
  success: {
    color: theme.colors.green[700],
    iconColor: theme.colors.green[700],
    backgroundColor: theme.colors.green[100],
    image: <DiscountTag />
  },
  info: {
    color: theme.colors.yellow[800],
    iconColor: theme.colors.brand[400],
    backgroundColor: theme.colors.yellow[100],
    image: <Callout />
  }
};
