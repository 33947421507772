import { createPayloadForWebCheckoutTrackingEvent } from "utils/mixpanel/web-checkout/web-checkout-mixpanel-utils";
import trackEvent from "services/mixpanel/mixpanel";
import { webCheckoutEventNames } from "services/mixpanel/web-checkout-events";
import { useAuth } from "stores/auth/auth-context";
import {
  IUsePackageSelector,
  IUsePackageSelectorProps
} from "./package-selector.types";

const usePackageSelector = ({
  summaryPackageData,
  packageName
}: IUsePackageSelectorProps): IUsePackageSelector => {
  const { user } = useAuth();

  const onTenureClick = (
    isTenureClickedFromPopup: boolean | undefined
  ): void => {
    let tenureForPayload: string | null;
    let packageForPayload: string | null;

    if (!summaryPackageData) {
      packageForPayload = packageName || null;
      tenureForPayload = null;
    } else {
      packageForPayload = summaryPackageData.name;
      tenureForPayload = summaryPackageData.tenure_details.tenure_text.text;
    }

    if (!isTenureClickedFromPopup) {
      const payload = createPayloadForWebCheckoutTrackingEvent({
        useAuthData: user,
        level: true,
        packageType: packageForPayload,
        tenureValue: tenureForPayload,
        Class: true,
        stage: null
      });
      trackEvent({
        eventName: webCheckoutEventNames.tenureClicked,
        payload
      });
    } else {
      const payload = createPayloadForWebCheckoutTrackingEvent({
        useAuthData: user,
        level: true,
        packageType: packageForPayload,
        tenureValue: tenureForPayload,
        Class: true,
        stage: null
      });
      trackEvent({
        eventName: webCheckoutEventNames.tenureClickedOnTenurePickerPopup,
        payload
      });
    }
  };
  return { onTenureClick };
};

export { usePackageSelector };
