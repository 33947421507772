import { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import {
  createPackageSubscription,
  getPackageById,
  getPackageByIdV1
} from "services/api";
import { useAuth } from "stores/auth/auth-context";
import {
  IPackageDetailsResponse,
  ISection
} from "services/api/packages/packageDetails.types";
import {
  IApiVersion,
  IUsePackageById,
  IUsePackageByIdProps
} from "./use-get-package-by-id.types";
import {
  IApiError,
  ISubmitPackageSubscriptionPayload
} from "services/api/api.types";
import { useGetCurrentPlatform } from "hooks/use-media-query/use-get-currrent-platform";
import { getSourceInfoEvent } from "utils/mixpanel/mixpanel-utils";

export const usePackageById = ({
  router,
  version = IApiVersion.VERSION_2,
  onSuccess
}: IUsePackageByIdProps): IUsePackageById => {
  const { user } = useAuth();
  const { userData } = user;
  const currentPlatform = useGetCurrentPlatform();
  const currentPlatformSource = getSourceInfoEvent(currentPlatform);

  const { package: packageId } = router.query;

  const [packageData, setPackageData] = useState<IPackageDetailsResponse>();
  const [sectionsData, setSectionsData] = useState<ISection[]>([]);
  const [selectedTenureId, setSelectedTenureId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchPackageV2Data() {
      try {
        const response = await getPackageById(
          String(packageId),
          userData?.primary_user?.access_token || ""
        );
        setPackageData(response);
        setSelectedTenureId(response.data.products[0].id);
        setIsLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        router.push({ pathname: "/404" });
      }
    }

    async function fetchPackageV1Data() {
      try {
        const response = await getPackageByIdV1(
          user.userData.primary_user.phone_number,
          currentPlatformSource,
          String(packageId),
          userData?.primary_user?.access_token || ""
        );
        setSectionsData(response.schedule.sections);
        onSuccess?.(response.schedule.sections);
        setIsLoading(false);
      } catch (err) {
        Sentry.captureException(err);
        router.push({ pathname: "/404" });
      }
    }

    if (router.isReady && packageId) {
      if (version === IApiVersion.VERSION_1) fetchPackageV1Data();
      else fetchPackageV2Data();
    }
  }, [router.isReady, packageId, userData?.primary_user, version]);

  const storePackageSubscription = async (
    payload: ISubmitPackageSubscriptionPayload
  ) => {
    try {
      await createPackageSubscription(
        payload,
        userData?.primary_user.access_token
      );
      return { error: null };
    } catch (error) {
      Sentry.captureException(error);
      const { message } = error as IApiError;
      return {
        error: message
      };
    }
  };

  return {
    packageData,
    selectedTenureId,
    isLoading,
    setSelectedTenureId,
    sectionsData,
    storePackageSubscription
  };
};
