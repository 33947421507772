import styled from "styled-components";
import { breakpoints, sizes } from "styles";

export const HeadingAndTenurePickerContainer = styled.div<{ layout: string }>`
  display: flex;
  flex-direction: column;

  @media ${breakpoints.laptop} {
    margin-bottom: ${({ layout }) => (layout === "modal" ? "0px" : "140px")};
  }
`;

export const HeadingAndSubjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 16px 40px 16px;

  @media ${breakpoints.tablet} {
    gap: 12px;
  }
`;

export const CardHeading = styled.h1<{ layout: string }>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["2xl"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;
  align-self: ${({ layout }) => (layout === "modal" ? `flex-start` : `center`)};

  @media ${breakpoints.tablet} {
    align-self: ${({ layout }) =>
      layout === "modal" ? `flex-start` : `center`};
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }
`;

export const CardSubHeading = styled.p<{ layout: string }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin: 15px 0 12px 0;
  font-weight: normal;
  line-height: 20px;
  font-size: ${({ theme }) => theme.fontSizes["sm"]};

  @media ${breakpoints.tablet} {
    align-self: ${({ layout }) =>
      layout === "modal" ? `flex-start` : `center`};
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    line-height: 24px;
  }
`;

export const SubjectsFlex = styled.div<{ layout: string }>`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  flex-wrap: wrap;
  align-self: flex-start;

  @media ${breakpoints.tablet} {
    align-self: ${({ layout }) =>
      layout === "modal" ? `flex-start` : `center`};
    gap: 12px;
  }
`;

export const PageOfferContainer = styled.div<{ variant: string }>`
  align-self: center;
  width: ${sizes.tenurePickerPageOfferWidth.mobile};
  display: flex;
  gap: 18px;
  align-items: flex-start;
  padding: 14px 16px;
  background: ${({ variant, theme }) =>
    variant === "green" ? theme.colors.green[50] : theme.colors.neutral[100]};
  border-radius: 6px;
  margin-top: 20px;

  @media ${breakpoints.laptop} {
    width: ${sizes.tenurePickerPageOfferWidth.laptop};
    gap: 18px;
    padding: 20px;
  }
`;

export const PageOfferIconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const PageOfferText = styled.p<{ variant: string }>`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["md"]};
  margin: 0;
  color: ${({ theme, variant }) =>
    variant === "green" ? theme.colors.green[800] : theme.colors.neutral[1000]};

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
  }
`;

export const TenureWrapper = styled.li<{ isDisabled: boolean }>`
  scroll-snap-align: center;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  box-shadow: 0px 4px 9px rgba(110, 116, 134, 0.05);
  margin: 16px;
`;

export const CTAWrapper = styled.div<{ layout: string }>`
  display: ${({ layout }) => (layout === "modal" ? `block` : `none`)};

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CTAAndAlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 20px 0;
  justify-content: flex-end;
  @media ${breakpoints.tablet} {
    width: 100%;
    gap: 20px;
  }
`;

export const ButtonContainer = styled.div`
  @media ${breakpoints.tablet} {
    position: fixed;
    left: 70%;
  }
`;

export const AlertContainer = styled.div`
  display: none;

  @media ${breakpoints.tablet} {
    display: unset;
  }
`;

export const AlertContainerForMobile = styled.div`
  display: unset;
  margin-top: 10px;

  @media ${breakpoints.tablet} {
    display: none;
  }
`;
