import { AlertContainer, AlertText, IconWrapper } from "./alert.styles";
import { IAlert } from "./alert.types";
import { alertData } from "./alert.data";
const Alert: React.FC<IAlert> = ({ alertText, variant }) => {
  return (
    <AlertContainer backgroundColor={alertData[variant].backgroundColor}>
      <IconWrapper iconColor={alertData[variant].iconColor}>
        {alertData[variant].image}
      </IconWrapper>
      <AlertText fontColor={alertData[variant].color}>{alertText}</AlertText>
    </AlertContainer>
  );
};

export { Alert };
