import React from "react";
import {
  CardHeading,
  CardSubHeading,
  SubjectsFlex,
  TenureWrapper,
  HeadingAndSubjectsContainer,
  HeadingAndTenurePickerContainer,
  AlertContainer,
  PageOfferContainer,
  PageOfferIconContainer,
  PageOfferText,
  CTAAndAlertContainer
} from "./tenure-picker-card.styles";
import { Alert } from "components/atoms/alert/alert";
import { ITenurePickerProps } from "./tenure-picker-card.types";
import { SubjectTag } from "components/subject-tag/subject-tag";
import { constants } from "constants/constants";

import { useTenurePickerCard } from "./use-tenure-picker-card";
import { PackageSelectionSticky } from "components/paket/package-selection-sticky/package-selection-sticky";
import { Button } from "components/atoms/button/button";
import { DiscountTag } from "components/icons/discount-tag";
import { NoDiscountTag } from "components/icons/no-discount-tag";
import { PackageSelector } from "../package-selector/package-selector";
import { ScrollSnapSlider } from "../scroll-snap-carousel/scroll-snap-carousel";

const TenurePickerCard: React.FC<ITenurePickerProps> = ({
  layout = "default",
  closeModal,
  packageDetails,
  selectedTenureId,
  setSelectedTenureId,
  errorMessage,
  mediaUrl,
  summaryPackageData
}) => {
  const {
    selectedTenureName,
    setSelectedTenureName,
    onProceed,
    selectedTenureBonusText
  } = useTenurePickerCard(summaryPackageData, selectedTenureId, packageDetails);

  return (
    <HeadingAndTenurePickerContainer layout={layout}>
      <HeadingAndSubjectsContainer>
        <CardHeading layout={layout}>
          {packageDetails?.page_info.title}
        </CardHeading>
        <CardSubHeading layout={layout}>
          {packageDetails?.page_info.description}
        </CardSubHeading>
        <SubjectsFlex layout={layout}>
          {packageDetails?.subjects.map((subjectData) => (
            <SubjectTag
              key={subjectData.id}
              text={subjectData.name}
              backgroundColor={subjectData.meta.backgroundColour}
              color={subjectData.meta.textColour}
              iconSrc={`${mediaUrl}/${subjectData.meta.iconSmall}`}
            />
          ))}
        </SubjectsFlex>
        {selectedTenureBonusText && (
          <PageOfferContainer
            variant={
              selectedTenureBonusText?.card_text === null ? "grey" : "green"
            }>
            <PageOfferIconContainer>
              {selectedTenureBonusText?.card_text === null ? (
                <NoDiscountTag />
              ) : (
                <DiscountTag />
              )}
            </PageOfferIconContainer>
            <PageOfferText
              variant={
                selectedTenureBonusText?.card_text === null ? "grey" : "green"
              }>
              {selectedTenureBonusText?.page_text}
            </PageOfferText>
          </PageOfferContainer>
        )}
      </HeadingAndSubjectsContainer>

      <ScrollSnapSlider>
        {packageDetails?.products.map((packageDetail) => (
          <TenureWrapper
            isDisabled={
              errorMessage
                ? packageDetail.tenure_type === constants.ONE_TIME_FIXED
                : false
            }
            onClick={() => {
              setSelectedTenureId(packageDetail.id);
              setSelectedTenureName(packageDetail.card_info.text);
            }}
            key={packageDetail.id}>
            <PackageSelector
              summaryPackageData={summaryPackageData}
              packageName={packageDetails?.name}
              {...packageDetail}
              isSelected={selectedTenureId === packageDetail.id}
              isTenureSelectedFromPopup={layout === "modal" ? true : false}
            />
          </TenureWrapper>
        ))}
      </ScrollSnapSlider>

      {layout === "modal" && (
        <CTAAndAlertContainer>
          {errorMessage && (
            <AlertContainer>
              <Alert variant="error" alertText={errorMessage} />
            </AlertContainer>
          )}

          <Button
            onClick={() => {
              if (closeModal) closeModal();
              onProceed();
            }}
            disabled={
              errorMessage &&
              packageDetails?.products.filter(
                (product) => product.tenure_type === constants.ONE_TIME_FIXED
              )[0].id === selectedTenureId
                ? true
                : false
            }
            variant="primary">
            Ganti periode belajar
          </Button>
        </CTAAndAlertContainer>
      )}
      {layout !== "modal" && (
        <PackageSelectionSticky
          selectedPackage={{
            name: `Periode Belajar ${selectedTenureName}`,
            grade: packageDetails?.grade,
            class_type: packageDetails?.class_type,
            id: selectedTenureId
          }}
          isPackageClickedOnMobile={false}
          onProceed={onProceed}
        />
      )}
    </HeadingAndTenurePickerContainer>
  );
};

export { TenurePickerCard };
