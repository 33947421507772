import styled from "styled-components";

export const AlertContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 8px;
  padding: 12px;
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
`;

export const AlertText = styled.p<{ fontColor: string }>`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ fontColor }) => fontColor};
  margin: 0;
`;
export const IconWrapper = styled.span<{ iconColor: string }>`
  display: flex;
  align-items: center;
  color: ${({ iconColor }) => iconColor};
`;
