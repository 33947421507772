import { useRouter } from "next/router";
import { useState } from "react";
import { Data } from "services/api/packages/packageDetails.types";
import { IPackageAndTenureSummaryDetails } from "services/api/packages/packageTenureSummaryDetails.types";
import { useAuth } from "stores/auth/auth-context";
import { IUseTenurePicker } from "./tenure-picker-card.types";

export const useTenurePickerCard = (
  summaryPackageData: IPackageAndTenureSummaryDetails | undefined,
  selectedTenureId: string,
  packageDetails?: Data
): IUseTenurePicker => {
  const router = useRouter();
  const {
    user: { isUserLoggedIn }
  } = useAuth();
  const isCurrentRouteTenurePick = router.pathname === "/paket/tenure-pick";

  const [selectedTenureName, setSelectedTenureName] = useState<
    string | undefined
  >(summaryPackageData?.name || packageDetails?.products[0]?.card_info?.text);

  const selectedTenureBonusText = packageDetails?.products?.find(
    (product) => product?.id === selectedTenureId
  )?.card_info?.bonus_text;

  const onProceed = () => {
    router.push({
      pathname: isUserLoggedIn
        ? isCurrentRouteTenurePick
          ? "/login"
          : router.pathname
        : "/login",
      query: {
        ...router.query,
        package: packageDetails?.id,
        tenure: selectedTenureId
      }
    });
  };

  return {
    selectedTenureName,
    setSelectedTenureName,
    onProceed,
    selectedTenureBonusText
  };
};
