import styled from "styled-components";
import { breakpoints, sizes } from "styles";

export const PackageCard = styled.div<{ selected: boolean }>`
  height: ${sizes.PackageCardHeight.mobile};
  cursor: pointer;
  padding: 20px 14px;
  border: ${({ theme, selected }) =>
    selected
      ? `2px solid ${theme.colors.brand[500]}`
      : `2px solid ${theme.colors.neutral[100]}`};
  border-radius: 8px;
  position: relative;
  width: ${sizes.packageSelectorCardWidth.mobile};

  @media ${breakpoints.laptop} {
    height: ${sizes.PackageCardHeight.laptop};
    width: ${sizes.packageSelectorCardWidth.laptop};
    font-size: ${({ theme }) => theme.fontSizes.sm};
    padding: 32px 24px;
  }
`;

export const PackageTitleContainer = styled.div<{ selected: boolean }>`
  padding: 4px 12px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.brand[500] : theme.colors.brand[100]};
  border-radius: 4px;
  position: absolute;
  top: -10px;
  left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.laptop} {
    left: 22px;
    top: -12px;
  }
`;

export const PackageTitle = styled.p<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.brand[500]};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  margin: 0 10px;
  font-size: ${({ theme }) => theme.fontSizes.xxs};

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }
`;

export const PackageDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PackageSemesterAndPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  @media ${breakpoints.laptop} {
    margin-bottom: 10px;
  }
`;

export const PackageSemester = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes.md};
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0px 0px;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["3xl"]};
  }
`;

export const BonusTextContainer = styled.div`
  width: ${sizes.packageSelectorBonusTextContainer.mobile};
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  padding: 4px 3px 3px;
  background: ${({ theme }) => theme.colors.green[800]};
  border-radius: 4px;

  @media ${breakpoints.laptop} {
    width: ${sizes.packageSelectorBonusTextContainer.laptop};
  }
`;

export const BonusText = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  color: ${({ theme }) => theme.colors.white};
  margin: 0px 10px;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const PackagePriceDetails = styled.div`
  display: flex;
  gap: 9px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 15px;

  @media ${breakpoints.laptop} {
    margin-top: 25px;
    gap: 4px;
  }
`;

export const DiscountAndOriginalPriceContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 0px;
`;

export const DiscountPercent = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  font-weight: bold;
  padding: 2px 4px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.pink[500]};
  line-height: 20px;
  text-align: center;

  @media ${breakpoints.laptop} {
    padding: 4px 6px 3px;
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const OriginalPrice = styled.p`
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  font-family: ${({ theme }) => theme.fonts.primary};
  text-decoration-line: line-through;
  color: ${({ theme }) => theme.colors.neutral[600]};

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    margin: 0;
  }
`;

export const DiscountedPrice = styled.h3`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["xs"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["lg"]};
    margin-top: 7px;
  }
`;

export const Instalment = styled.p`
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.neutral[900]};
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
    margin-top: 7px;
  }
`;

export const PackageDateContainer = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const LearningPeriod = styled.p`
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  color: ${({ theme }) => theme.colors.neutral[600]};
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;

export const PackageDate = styled.div`
  font-size: ${({ theme }) => theme.fontSizes["xxs"]};
  color: ${({ theme }) => theme.colors.neutral[900]};
  font-weight: bold;

  @media ${breakpoints.laptop} {
    font-size: ${({ theme }) => theme.fontSizes["md"]};
  }
`;
