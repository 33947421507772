import {
  currencyDelimeter,
  getDiscountFromDiscountString,
  getMonthAndYear
} from "components/web-checkout/utils";
import {
  PackageCard,
  PackageDetails,
  PackageTitle,
  PackageTitleContainer,
  PackageDateContainer,
  PackageSemester,
  PackagePriceDetails,
  PackageSemesterAndPriceContainer,
  LearningPeriod,
  PackageDate,
  DiscountAndOriginalPriceContainer,
  DiscountPercent,
  OriginalPrice,
  DiscountedPrice,
  Instalment,
  BonusTextContainer,
  BonusText
} from "./package-selector.styles";
import { IPackageSelectorData } from "./package-selector.types";
import { usePackageSelector } from "./use-package-selector";

const PackageSelector: React.FC<IPackageSelectorData> = ({
  summaryPackageData,
  isSelected,
  isTenureSelectedFromPopup,
  list_price,
  base_price,
  tenure_ends_at,
  tenure_starts_at,
  price_per_month,
  card_info,
  discount_info,
  packageName
}) => {
  const { onTenureClick } = usePackageSelector({
    summaryPackageData,
    packageName
  });

  return (
    <PackageCard
      onClick={() => onTenureClick(isTenureSelectedFromPopup)}
      selected={isSelected}>
      <PackageTitleContainer selected={isSelected}>
        <PackageTitle selected={isSelected}>{card_info.title}</PackageTitle>
      </PackageTitleContainer>
      <PackageDetails>
        <PackageSemesterAndPriceContainer>
          <PackageSemester>{card_info.text}</PackageSemester>
          {card_info.bonus_text?.card_text && (
            <BonusTextContainer>
              <BonusText>{card_info.bonus_text?.card_text}</BonusText>
            </BonusTextContainer>
          )}
          <PackagePriceDetails>
            <DiscountAndOriginalPriceContainer>
              {Number(getDiscountFromDiscountString(discount_info)) > 0 && (
                <DiscountPercent>
                  {getDiscountFromDiscountString(discount_info)}%
                </DiscountPercent>
              )}
              <OriginalPrice>Rp {currencyDelimeter(base_price)}</OriginalPrice>
            </DiscountAndOriginalPriceContainer>
            <DiscountedPrice>
              Rp {currencyDelimeter(list_price)}
            </DiscountedPrice>
            <Instalment>
              Setara Rp {currencyDelimeter(price_per_month)}/bulan
            </Instalment>
          </PackagePriceDetails>
        </PackageSemesterAndPriceContainer>
        <PackageDateContainer>
          <LearningPeriod>{card_info.period_text}</LearningPeriod>
          <PackageDate>
            {getMonthAndYear(tenure_starts_at)} -
            {getMonthAndYear(tenure_ends_at)}
          </PackageDate>
        </PackageDateContainer>
      </PackageDetails>
    </PackageCard>
  );
};

export { PackageSelector };
