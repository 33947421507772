import {
  IPackageDetailsResponse,
  ISection
} from "services/api/packages/packageDetails.types";
import { NextRouter } from "next/router";
import {
  ISubmitPackageSubscriptionPayload,
  ISubmitPackageSubscriptionResponse
} from "services/api/api.types";

export interface IUsePackageByIdProps {
  router: NextRouter;
  version?: IApiVersion;
  onSuccess?: (sectionsData: ISection[]) => void;
}

export interface IUsePackageById {
  packageData: IPackageDetailsResponse | undefined;
  sectionsData: ISection[];
  selectedTenureId: string;
  isLoading: boolean;
  setSelectedTenureId: React.Dispatch<React.SetStateAction<string>>;
  storePackageSubscription: (
    payload: ISubmitPackageSubscriptionPayload
  ) => Promise<ISubmitPackageSubscriptionResponse>;
}

export enum IApiVersion {
  VERSION_1 = "v1",
  VERSION_2 = "v2"
}
