import React from "react";
import { IIcon } from "./icons.types";

const Warning: React.FC<IIcon> = ({
  width = 16,
  height = 16,
  color = "currentColor"
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill={color}
      />
      <path
        d="M6.40401 5.49619L6.99129 9.38691C7.06469 9.82735 7.43173 10.1944 7.94562 10.1944C8.38606 10.1944 8.82656 9.82735 8.89996 9.38691L9.48724 5.49619C9.63404 4.54186 8.89996 3.73438 7.94562 3.73438C6.99124 3.73432 6.33056 4.54186 6.40401 5.49619Z"
        fill="white"
      />
      <path
        d="M7.94734 12.7638C8.43387 12.7638 8.82827 12.3694 8.82827 11.8829C8.82827 11.3964 8.43387 11.002 7.94734 11.002C7.46081 11.002 7.06641 11.3964 7.06641 11.8829C7.06641 12.3694 7.46081 12.7638 7.94734 12.7638Z"
        fill="white"
      />
    </svg>
  );
};

export { Warning };
